const MemberIcon = () => {
  return (
    <svg
      className='w-[30px] h-[30px] 2xl:w-[37px] 2xl:h-[37px]'
      viewBox='0 0 37 53'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.75 52.3191C11.75 52.3191 1.75 33.3191 1.75 25.3191C1.75 17.3191 9.75 4.81912 19.75 4.81912C29.75 4.81912 36.75 17.3191 36.75 24.8191C36.75 32.3191 27.75 52.3191 19.75 52.3191Z'
        fill='#F28300'
      />
      <path
        d='M13.75 10.3191C13.75 10.3191 7.25 18.8191 5.75 20.3191C4.25 21.8191 1.25 29.3191 1.25 29.3191C1.25 29.3191 0.250004 17.8191 2.25 15.3191C4.25 12.8191 11.25 3.81913 14.75 2.31913C18.25 0.819134 27.25 0.319127 30.25 2.31912C33.25 4.31912 35.75 9.81913 36.25 11.8191C36.75 13.8191 36.25 28.8191 36.25 28.8191L31.75 13.8191V21.3191L28.25 11.8191L20.25 9.31912L16.25 15.3191L17.25 9.81913L12.25 18.3191L13.75 10.3191Z'
        fill='black'
        stroke='black'
      />
      <circle cx='11.75' cy='24.8191' r='2.5' fill='#444444' />
      <circle cx='26.75' cy='24.8191' r='2.5' fill='#444444' />
      <path
        d='M11.25 40.8191C11.25 40.8191 19.75 42.8191 24.75 42.8191C29.75 42.8191 26.25 41.3191 26.25 41.3191'
        stroke='black'
        stroke-linecap='round'
      />
    </svg>
  );
};

export default MemberIcon;
