const DoorIcon = () => {
  return (
    <svg
      className='w-[30px] h-[30px] 2xl:w-[37px] 2xl:h-[37px]'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.3636 9.63636V7.36364C15.3636 6.61052 14.7531 6 14 6H5.36363C4.61052 6 4 6.61052 4 7.36364V19.6364C4 20.3895 4.61052 21 5.36364 21H14C14.7531 21 15.3636 20.3895 15.3636 19.6364V16.6818'
        stroke='#EB5757'
      />
      <path
        d='M20.9445 13.8536C21.1397 13.6583 21.1397 13.3417 20.9445 13.1464L17.7625 9.96447C17.5672 9.7692 17.2506 9.7692 17.0554 9.96447C16.8601 10.1597 16.8601 10.4763 17.0554 10.6716L19.8838 13.5L17.0554 16.3284C16.8601 16.5237 16.8601 16.8403 17.0554 17.0355C17.2506 17.2308 17.5672 17.2308 17.7625 17.0355L20.9445 13.8536ZM7.40909 14H20.5909V13H7.40909V14Z'
        fill='#EB5757'
      />
    </svg>
  );
};

export default DoorIcon;
