const TvFeet = () => {
  return (
    <svg
      width='388'
      height='31'
      viewBox='0 0 388 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_26_85)'>
        <path
          d='M4.38688 21.2641L27.213 0.264069C27.3976 0.0942526 27.6392 0 27.89 0H44.5174C45.3382 0 45.8094 0.934301 45.3215 1.59439L29.7998 22.5944C29.6112 22.8495 29.3128 23 28.9956 23H5.06394C4.15281 23 3.71636 21.881 4.38688 21.2641Z'
          fill='black'
        />
      </g>
      <g filter='url(#filter1_d_26_85)'>
        <path
          d='M341.887 1.73593L364.713 22.7359C364.898 22.9057 365.139 23 365.39 23H382.017C382.838 23 383.309 22.0657 382.822 21.4056L367.3 0.405609C367.111 0.150509 366.813 0 366.496 0H342.564C341.653 0 341.216 1.11905 341.887 1.73593Z'
          fill='black'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_26_85'
          x='0.0620117'
          y='0'
          width='49.457'
          height='31'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_26_85'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_26_85'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_d_26_85'
          x='337.562'
          y='0'
          width='49.457'
          height='31'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_26_85'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_26_85'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default TvFeet;
